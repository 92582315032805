import { useMemo } from "react";
import { produce } from "immer";
import { CimDoc } from "@design-stack-vista/cdif-types";
import { useDesignEngine } from "@design-stack-vista/core-features";
import {
    mapDesignAttributeToProductOption,
    mapProductOptionValueToDesignAttributeValue
} from "@internal/utils-studio-configuration-core";
import { DSS } from "@vp/types-ddif";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { removeUnreplacedPlaceholdersFromCimDoc } from "@internal/utils-cimdoc";

type UsePanelPreviewDataReturn = {
    cimDoc: DSS.DesignDocument;
    // TODO: use SceneConfiguration type
    sceneConfiguration: any | undefined;
    isFoldedProduct: boolean;
};

type Props = {
    useDesignAttributeMappings: () => any;
    // TODO: use SceneConfiguration type
    useSceneConfiguration: () => any | undefined;
};

export const usePanelPreviewData = (props: Props): UsePanelPreviewDataReturn => {
    const { cimDocStore } = useDesignEngine();
    const { useSceneConfiguration, useDesignAttributeMappings } = props;
    const sceneConfiguration = useSceneConfiguration();

    const designAttributeMappings = useDesignAttributeMappings();
    const { studioSelectedProductOptions } = useProductAndProjectStateManager().data;

    const isFoldedProduct = useMemo<boolean>(() => {
        const foldedProductOption = mapDesignAttributeToProductOption(designAttributeMappings, "Fold");
        return (
            !!studioSelectedProductOptions[foldedProductOption] &&
            mapProductOptionValueToDesignAttributeValue(
                designAttributeMappings,
                foldedProductOption,
                studioSelectedProductOptions[foldedProductOption]
            ) === "Folded"
        );
    }, [designAttributeMappings, studioSelectedProductOptions]);

    const cimDoc = useMemo<CimDoc>(() => {
        return produce(cimDocStore.asJson, draft => {
            const newCimDoc = removeUnreplacedPlaceholdersFromCimDoc(draft);
            if (isFoldedProduct) {
                delete newCimDoc.projectionId;
            }
            return newCimDoc;
        });
    }, [cimDocStore.asJson, isFoldedProduct]);

    return { cimDoc, sceneConfiguration, isFoldedProduct };
};
