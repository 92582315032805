import React from "react";
import { BasicResponsiveImage, Box } from "@vp/swan";
import { observer } from "mobx-react-lite";
import * as styles from "./ServerSidePanelPreview.module.scss";

type ServerSidePanelPreviewProps = {
    alt: string;
    previewFallbackUrl: string;
    aspectRatio: number;
    isCanvasSelectorPreview: boolean;
};

export const ServerSidePanelPreview = observer(
    ({ alt, previewFallbackUrl, aspectRatio, isCanvasSelectorPreview }: ServerSidePanelPreviewProps) => {
        return (
            <Box p={isCanvasSelectorPreview ? "4" : 0} className={styles.serverSideCanvasImageContainer}>
                <BasicResponsiveImage aspectRatio={aspectRatio} alt={alt} src={previewFallbackUrl} />
            </Box>
        );
    }
);

ServerSidePanelPreview.displayName = "ServerSidePanelPreview";
