import React, { useContext } from "react";
import type { DSS } from "@vp/types-ddif";
import { PreviewUrl, GetAltText } from "./previewsContextTypes";

interface PreviewContextType {
    canvasSelectorUrls: PreviewUrl[];
    previewUrls: PreviewUrl[];
    designDocument: DSS.DesignDocument | undefined;
    previewInstructionsUrl: string;
    getAltTextForPreview: GetAltText;
}

export const PreviewsContext = React.createContext<PreviewContextType>({
    canvasSelectorUrls: [],
    previewUrls: [],
    designDocument: {} as DSS.DesignDocument,
    previewInstructionsUrl: "",
    getAltTextForPreview: () => null
});

/**
 * @returns {{canvasSelectorUrls: any[], previewUrls: any[], purcsTransientUrls: any[], previewInstructionsUrl: string}}
 */
export const usePreviewsContext = () => {
    return useContext(PreviewsContext);
};
