import React, { ReactNode, useCallback, useMemo } from "react";
import { FusionPreviewProps, FusionPreview } from "@rendering/product-preview-react";
import { DSS } from "@vp/types-ddif";
import { observer } from "mobx-react-lite";
import { Box } from "@vp/swan";
import classnames from "classnames";
import { CimDoc } from "@design-stack-vista/cdif-types";
import { useIsFlagEnabled } from "@internal/utils-flags";
import { REQUESTOR } from "@internal/utils-network";
import { filterNonServerRenderableItems } from "@internal/utils-cimdoc";
import * as styles from "./ClientSidePanelPreview.module.scss";

function mapPurcsViewToLookupView({
    sceneType,
    sceneUrl,
    page
}: {
    sceneUrl: string;
    sceneType: string;
    page: number;
}): FusionPreviewProps["view"] {
    // The only attributes that matter for <FusionPreview /> panel previews are:
    // type
    // panel.page
    if (!sceneType) {
        return {
            type: "panel",
            purpose: "",
            subPurpose: "",
            name: "",
            panel: {
                page,
                aspectRatio: 1 // fake data
            }
        };
    }

    // The only attributes that matter for <FusionPreview /> scene previews are:
    // type
    // scene.type
    // scene.url
    // documents[].page
    return {
        type: "scene",
        purpose: "",
        subPurpose: "",
        name: "",
        scene: {
            id: "",
            type: sceneType.toLowerCase() as "photographic" | "dynamic",
            url: sceneUrl,
            documents: [{ page, blendingMode: "normal" }],
            dimensions: {
                width: 0,
                height: 0
            }
        }
    };
}

export type DebugContextMenuChildrenArgs = {
    onContextMenu: (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => void;
};
export type DebugContextMenuProps = {
    children: (args: DebugContextMenuChildrenArgs) => ReactNode | ReactNode[];
    cimDoc: CimDoc;
    view: FusionPreviewProps["view"];
    top?: string;
    width?: number | string;
};

type ClientSidePanelPreviewProps = {
    page: number;
    sceneUrl: string;
    sceneType: string;
    onError: (e: unknown) => void;
    cimDoc: DSS.DesignDocument;
    alt: string;
    aspectRatio: number;
    isCanvasSelectorPreview: boolean;
    DebugContextMenuTrigger: React.FC<DebugContextMenuProps>;
};

export const ClientSidePanelPreview = observer(
    ({
        page,
        sceneUrl,
        sceneType,
        cimDoc,
        onError,
        alt,
        aspectRatio,
        isCanvasSelectorPreview,
        DebugContextMenuTrigger
    }: ClientSidePanelPreviewProps) => {
        const user = useMemo<FusionPreviewProps["user"]>(() => {
            return {
                tenant: { id: "vistaprint-prod", type: "merchants" },
                referer: REQUESTOR
            };
        }, []);

        // If the preview is for the Canvas Selectors
        // we need to calculate the size of the preview
        // before it is loaded to avoid any jumpyness
        const maxWidth = 68;
        const maxHeight = 68;
        let height = isCanvasSelectorPreview ? maxWidth / aspectRatio : "100%";
        let width = isCanvasSelectorPreview ? 68 : "100%";
        if (typeof height === "number" && height > maxHeight) {
            width = maxHeight * aspectRatio;
            height = width / aspectRatio;
        }

        const rtextEnable = useIsFlagEnabled("rtext");
        const fusionOptions = useMemo<FusionPreviewProps["fusionOptions"]>(() => {
            if (rtextEnable) {
                return {
                    textOptions: {
                        rtextEnabled: true
                    }
                };
            }
            return undefined;
        }, [rtextEnable]);

        const view = useMemo<FusionPreviewProps["view"]>(() => {
            return mapPurcsViewToLookupView({ page, sceneType, sceneUrl });
        }, [page, sceneType, sceneUrl]);

        const transformCimDocForServerFallback = useCallback((cimDocToTransform: FusionPreviewProps["cimDoc"]) => {
            return filterNonServerRenderableItems(cimDocToTransform as CimDoc) as FusionPreviewProps["cimDoc"];
        }, []);

        return (
            <Box className={styles.canvasContainer} p={isCanvasSelectorPreview ? "4" : 0}>
                <div
                    style={{
                        display: "flex",
                        width,
                        height,
                        backgroundColor: isCanvasSelectorPreview ? "white" : "unset"
                    }}
                >
                    <FusionPreview
                        cimDoc={cimDoc as FusionPreviewProps["cimDoc"]} // uses different cimDoc type
                        user={user}
                        view={view}
                        onError={onError}
                        transformCimDocForServerFallback={transformCimDocForServerFallback}
                        fusionOptions={fusionOptions}
                    >
                        {({ Canvas }) => (
                            <DebugContextMenuTrigger cimDoc={cimDoc} view={view} top="100%" width="100%">
                                {({ onContextMenu }) => (
                                    <Canvas
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: isCanvasSelectorPreview ? "fill" : "contain"
                                        }}
                                        onContextMenu={onContextMenu}
                                        className={classnames({
                                            [styles.boxShadow]: !isCanvasSelectorPreview
                                        })}
                                    >
                                        {alt}
                                    </Canvas>
                                )}
                            </DebugContextMenuTrigger>
                        )}
                    </FusionPreview>
                </div>
            </Box>
        );
    }
);

ClientSidePanelPreview.displayName = "ClientSidePanelPreview";
