import React, { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { DSS } from "@vp/types-ddif";
import { ERROR_CODES, useErrors } from "@internal/utils-errors";
import type { StudioError } from "@internal/utils-errors";
import type { SceneConfig } from "@internal/data-access-calcifer";
import { parseMM } from "@rendering/fusion-react";
import type { UseStudioConfigurationManager } from "@internal/utils-studio-configuration-core";
import { ClientSidePanelPreview, DebugContextMenuProps } from "./ClientSidePanelPreview";
import { ServerSidePanelPreview } from "./ServerSidePanelPreview";

type PanelPreviewProps = {
    sceneConfiguration: any | undefined;
    page: number;
    cimDoc: DSS.DesignDocument;
    previewFallbackUrl: string;
    isFoldedProduct: boolean;
    alt: string;
    isCanvasSelectorPreview?: boolean;
    DebugContextMenuTrigger: React.FC<DebugContextMenuProps>;
    useStudioConfigurationManager: UseStudioConfigurationManager;
};

const ENTITY_CODE = 10;

function isViewClientSideRenderable({
    sceneType = "",
    sceneUrl,
    useNoScene = false
}: {
    sceneUrl: string;
    sceneType: string | undefined;
    useNoScene: boolean;
}): boolean {
    const returnValue = useNoScene || (!!sceneUrl && ["photographic", "dynamic"].includes(sceneType.toLowerCase()));
    return returnValue;
}

const getSceneConfig = ({
    sceneConfiguration,
    index,
    cimDoc,
    isFoldedProduct
}: {
    sceneConfiguration: any | undefined;
    index: number;
    cimDoc: DSS.DesignDocument;
    isFoldedProduct: boolean;
}): SceneConfig | undefined => {
    // No scene for folded products
    if (isFoldedProduct) {
        return undefined;
    }

    // Use underlay scene if present with a document slot
    const hasUnderlaySceneWithDocumentSlot =
        (sceneConfiguration?.fullSceneConfiguration.underlay[index]?.sceneInfo?.slots.length ?? 0) > 0;
    // Don't use underlay scene when cimDoc has a projection
    if (hasUnderlaySceneWithDocumentSlot && !cimDoc.projectionId) {
        return sceneConfiguration!.fullSceneConfiguration.underlay[index];
    }

    // Otherwise use transient scene
    return sceneConfiguration?.fullSceneConfiguration.transient[index];
};

export const PanelPreview = observer(
    ({
        alt,
        previewFallbackUrl,
        cimDoc,
        page,
        sceneConfiguration,
        isFoldedProduct,
        isCanvasSelectorPreview = false,
        DebugContextMenuTrigger,
        useStudioConfigurationManager
    }: PanelPreviewProps) => {
        const [hasClientSideError, setHasClientSideError] = useState<boolean>(false);
        const {
            data: { forceServerSideRendering, shouldSaveAdaptStickerDocument }
        } = useStudioConfigurationManager();

        const { handleError } = useErrors();

        const onClientSideError = useCallback(
            (e: unknown) => {
                setHasClientSideError(true);
                handleError(e as StudioError, ERROR_CODES.FUSION_PANEL_PREVIEW, ENTITY_CODE, false, true, true);
            },
            [handleError]
        );

        // The indexes between the pages and the scenes are off by 1
        const sceneConfig = getSceneConfig({ sceneConfiguration, index: page - 1, cimDoc, isFoldedProduct });
        const sceneUrl = sceneConfig?.url ?? "";
        const sceneType = sceneConfig?.sceneType.toLowerCase() ?? "";
        const shouldUseServerFallback =
            // custom sticker logic is async and requires significant document updates.
            // client side previews expect a synchronous document so handling async would be annoying
            shouldSaveAdaptStickerDocument ||
            forceServerSideRendering ||
            hasClientSideError ||
            !isViewClientSideRenderable({ sceneType, sceneUrl, useNoScene: isFoldedProduct });

        const aspectRatio = sceneConfig?.sceneInfo
            ? sceneConfig.sceneInfo.width / sceneConfig.sceneInfo.height
            : parseMM(cimDoc.document.panels[page - 1].width) / parseMM(cimDoc.document.panels[page - 1].height);

        if (shouldUseServerFallback) {
            return (
                <ServerSidePanelPreview
                    aspectRatio={1 / aspectRatio}
                    alt={alt}
                    previewFallbackUrl={previewFallbackUrl}
                    isCanvasSelectorPreview={isCanvasSelectorPreview}
                />
            );
        }

        return (
            <ClientSidePanelPreview
                DebugContextMenuTrigger={DebugContextMenuTrigger}
                onError={onClientSideError}
                page={page}
                sceneUrl={sceneUrl}
                sceneType={sceneType}
                cimDoc={cimDoc}
                alt={alt}
                aspectRatio={aspectRatio}
                isCanvasSelectorPreview={isCanvasSelectorPreview}
            />
        );
    }
);

PanelPreview.displayName = "PanelPreview";
