import { useIsFlagEnabled } from "@internal/utils-flags";
import { useMemo } from "react";
import { usePreviewsContext } from "../usePreviewContext";
import type { UseStudioConfigurationManager } from "@internal/utils-studio-configuration-core";
import { useStudioFlexibility } from "@internal/ab-test-studio-flexibility";

type useIsVortexEnabledProps = {
    useActiveFlexibilityOptions: () => any;
    FlexibilityDesignAttributes: any;
    useAvailablePremiumFinishesForSurfaces: () => any;
    useTemplateAvailableFinishes: () => any;
    useStudioConfigurationManager: UseStudioConfigurationManager;
};

export function useIsVortexEnabled({
    useStudioConfigurationManager,
    useAvailablePremiumFinishesForSurfaces,
    useTemplateAvailableFinishes,
    useActiveFlexibilityOptions,
    FlexibilityDesignAttributes
}: useIsVortexEnabledProps) {
    const { isMileStone1Enabled, isMileStone2Enabled, isMileStone3Enabled } = useStudioFlexibility();
    const { displayedDesignAttributeName } = useActiveFlexibilityOptions();
    const {
        data: { useReactVortexPreview, forceServerSideRendering }
    } = useStudioConfigurationManager();
    const { hasPremiumFinishesAvailable } = useAvailablePremiumFinishesForSurfaces();
    const { previewInstructionsUrl } = usePreviewsContext();

    const templateAvailableFinishes = useTemplateAvailableFinishes();
    // this boolean is to check whether or not we want to enable vortex on the PF flextibility panel.
    const anyAvailableFinishes =
        isMileStone2Enabled &&
        displayedDesignAttributeName === FlexibilityDesignAttributes.PremiumFinish &&
        templateAvailableFinishes &&
        templateAvailableFinishes.length > 0;

    const isFlexibilityFeaturesEnabled =
        (isMileStone1Enabled || isMileStone2Enabled || isMileStone3Enabled) &&
        displayedDesignAttributeName !== FlexibilityDesignAttributes.Size;

    const forceUseVortex = useIsFlagEnabled("forceUseVortex");

    const enableVortex = useMemo(
        () =>
            (hasPremiumFinishesAvailable ||
                useReactVortexPreview ||
                anyAvailableFinishes ||
                isFlexibilityFeaturesEnabled ||
                forceUseVortex) &&
            previewInstructionsUrl &&
            !forceServerSideRendering,
        [
            hasPremiumFinishesAvailable,
            previewInstructionsUrl,
            useReactVortexPreview,
            anyAvailableFinishes,
            isFlexibilityFeaturesEnabled,
            forceUseVortex,
            forceServerSideRendering
        ]
    );

    return enableVortex;
}
